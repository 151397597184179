'use client'

import Image from 'next/image'
import {
    getPrettyGear,
    getPrettyFuel,
    getDefaultMileage,
    getDefaultProduct,
    getDefaultTerm,
    getVAT,
    roundNumber,
    getCampaignURL,
} from 'utils/displayHelpers'
import Link from 'next/link'

import { event } from 'utils/gtm'
import { clickProduct } from 'utils/ecommerce'

import { Comerciales2, ofertas60Aniversario } from 'utils/campaigns'

export default function Square({
    campaign,
    section,
    tipologia,
    subseccion1,
    title,
    position,
    noLink,
    customLink,
    onClick,
    variant,
    notForIndividual,
    queryParams,
    overwriteQuoteWithoutVAT,
    className,
}) {
    const defaultProduct = getDefaultProduct(campaign)
    const defaultMileage = getDefaultMileage(campaign)
    const defaultTerm = getDefaultTerm(campaign, tipologia)
    const fuel = getPrettyFuel(campaign)
    const gear = getPrettyGear(campaign)
    const quoteWithoutVAT =
        overwriteQuoteWithoutVAT || campaign.quotes[defaultProduct][defaultTerm][defaultMileage].monthlyQuote
    const quoteWithVAT = overwriteQuoteWithoutVAT
        ? overwriteQuoteWithoutVAT * getVAT()
        : campaign.quotes[defaultProduct][defaultTerm][defaultMileage].monthlyQuote * getVAT()

    if (!variant && campaign?.variant) variant = campaign.variant

    let destinationUrl = customLink || getCampaignURL(campaign, tipologia)
    if (queryParams) {
        destinationUrl = `${destinationUrl}${queryParams}`
    }
    let previousPrice = campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote
        ? roundNumber(campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote)
        : null

    if (
        previousPrice &&
        campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote <= quoteWithoutVAT
    )
        previousPrice = null

    let bandsBottom = []
    let bandsTop = []

    const normalizedDescription = `${campaign.brand}_${campaign.model}`.toLowerCase()

    const handleClick = () => {
        event({ event: 'gaEvent', location: section, event_name: 'oferta', detail: normalizedDescription })
        if (title) {
            let product = {
                name: `${campaign.brand}_${campaign.model}`,
                category: `${tipologia}`,
                list: `${title}`,
                brand: `${campaign.brand}`,
                dimension2: `${section}`,
                dimension3: `${subseccion1 || ''}`,
                position: position + 1,
                productType: 'upfront',
            }
            clickProduct(product, title)
        }
        if (onClick) {
            onClick()
        }
    }

    if (campaign?.fullequipe) {
        bandsBottom.push(
            <div
                key="superequipado"
                className="inline-block rounded-full bg-lpfreshgreen px-3 py-1 text-center text-xs text-white"
            >
                Super equipado
            </div>
        )
    }

    if (notForIndividual) {
        bandsBottom.push(
            <div key="" className="degLightBlue inline-block rounded-full px-3 py-1 text-center text-xs text-white">
                Solo para empresas y autónomos
            </div>
        )
    }

    if (campaign?.stockRegistered > 0) {
        bandsBottom.push(
            <div
                key="entregarapida"
                className="degLightBlue inline-block rounded-full px-3 py-1 text-center text-xs text-white"
            >
                <div className="relative top-0.5 mr-2 inline-block h-3.5 w-3.5">
                    <Image
                        src="/assets/icons/entrega-rapida.svg"
                        width={11}
                        height={10}
                        alt="Entrega rápida"
                        sizes="100vw"
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                    />
                </div>
                Entrega rápida
            </div>
        )
    }

    if (campaign?.stock < 11) {
        bandsTop.push(
            <div key="ultimasunidades" className="bg-lplightorange py-0.5">
                Últimas unidades
            </div>
        )
    }

    if (campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote || campaign?.bajadaDePrecio) {
        bandsTop.push(
            <div key="bajadadeprecio" className="bg-lpsecondary py-0.5">
                Bajada de precio
            </div>
        )
    }

    if (campaign?.planMoves) {
        bandsTop.push(
            <div key="plan-moves" className="bg-[#FFC342] py-0.5 text-lpsecondary">
                Plan Moves III
            </div>
        )
    }

    if (variant === 'rentinazo-aportacion') {
        bandsTop = []
        bandsTop.push(
            <div key="rentinazo-aportacion" className="bg-lpsecondary py-1.5 text-sm">
                Aportación inicial de 3.900€
            </div>
        )
    }

    bandsBottom.slice(0, 2)
    // Para asegurarnos que no se que vacio e igualar
    bandsTop.slice(0, 2)

    if (bandsTop.length === 0 && variant === 'rentinazo-aportacion')
        bandsTop.push(
            <div key="vacio" className="py-1.5">
                &nbsp;
            </div>
        )
    if (bandsTop.length === 0 && variant !== 'rentinazo-aportacion')
        bandsTop.push(
            <div key="vacio" className="py-0.5">
                &nbsp;
            </div>
        )

    const getVariantStyles = function (variant, position) {
        if (!variant) {
            switch (position) {
                case 0:
                    return 'text-lpprimary '
                case 1:
                    return 'text-lpneutral20 '
                case 3:
                    return 'text-lpneutral40  '
                case 3:
                    return 'text-lpneutral40  '
                default:
                    return ''
            }
        } else if (variant === 'orange') {
            switch (position) {
                case 0:
                    return 'text-white '
                case 1:
                    return 'text-white '
                case 3:
                    return 'text-lpprimary '
                default:
                    return ''
            }
        }
    }

    const Content = (
        <div className={`relative${className ? className : ''}`}>
            <div
                className={`overflow-hidden rounded-lg bg-white shadow-700 ${
                    campaign.displayVariant === 'orange' ? 'degPrimary' : ''
                } ${campaign.outOfStock ? 'opacity-20' : ''}`}
            >
                <div
                    className={`grid ${
                        bandsTop.length === 2 ? 'grid-cols-2' : 'grid-cols-1'
                    } border-t-lg text-center text-xs text-white`}
                >
                    {bandsTop}
                </div>
                <div className="px-4 py-3 text-center">
                    <div
                        className={`${getVariantStyles(
                            campaign.displayVariant,
                            0
                        )} truncate text-xl font-semibold line-clamp-1`}
                    >
                        {campaign.brand} {campaign.model}
                    </div>
                    <div className={`${getVariantStyles(campaign.displayVariant, 1)} truncate font-thin line-clamp-1`}>
                        {campaign.variantDescription || <span>&nbsp;</span>}
                    </div>
                    <div className="relative pb-5">
                        <div className="relative h-[130px]">
                            <Image
                                src={campaign.image}
                                alt={`${campaign.brand} ${campaign.model}`}
                                fill
                                sizes="100vw"
                                style={{
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                        <div
                            className={` ${
                                bandsBottom.length < 2 ? '' : 'grid grid-cols-2'
                            } absolute bottom-0 left-0 right-0 -mx-1.5 my-2 gap-2`}
                        >
                            {bandsBottom}
                        </div>
                        {(campaign.electric || campaign.pluginhybrid || campaign.cero || fuel === 'Eléctrico') && (
                            <>
                                <div className="absolute bottom-24 left-0">
                                    <Image
                                        src={`/assets/icons/etiqueta-cero.svg`}
                                        width={36}
                                        height={38}
                                        alt="Etiqueta Cero"
                                    />
                                </div>
                                {/*
                                    <div className="absolute left-0 bottom-24">
                                            <Image src={campaign.displayVariant !== 'orange' ? `/assets/global/icons/icon-patinete.svg` : `/assets/global/icons/icon-patinete-white.svg`} width={36} height={38} alt="Etiqueta Eco" />
                                    </div>
                                     */}
                                {/*
                            <>
                                <div className="absolute right-0 bottom-24">
                                    <Image src={`/assets/global/icons/movistarcar.png`} width={38} height={38} alt="Movistar Car" />
                                </div>
                            </>
                            */}
                            </>
                        )}
                        {campaign.eco && (
                            <>
                                <div className="absolute bottom-24 left-0">
                                    <Image
                                        src={`/assets/icons/etiqueta-eco.svg`}
                                        width={36}
                                        height={38}
                                        alt="Etiqueta Eco"
                                    />
                                </div>
                                {/*
                                        <div className="absolute left-0 bottom-24">
                                            <Image src={campaign.displayVariant !== 'orange' ? `/assets/global/icons/icon-patinete.svg` : `/assets/global/icons/icon-patinete-white.svg`} width={36} height={38} alt="Etiqueta Eco" />
                                        </div>
                            */}
                                {/*
                            <>
                                <div className="absolute right-0 bottom-24">
                                    <Image src={`/assets/global/icons/movistarcar.png`} width={38} height={38} alt="Movistar Car" />
                                </div>
                            </>
                            */}
                            </>
                        )}
                        {/*
                    {(campaign.movistarcar) &&
                        <>
                            <div className="absolute right-0 bottom-24">
                                <Image src={`/assets/global/icons/movistarcar.png`} width={38} height={38} alt="Movistar Car" />
                            </div>
                        </>
                    }
                     */}
                        {variant === '400euros' && (
                            <>
                                <div className="absolute bottom-24 right-0">
                                    <Image
                                        src={`/assets/global/icons/400-eur-combustible.png`}
                                        width={38}
                                        height={38}
                                        alt="400€ de combustible gratis"
                                    />
                                </div>
                            </>
                        )}
                        {/*
                        {[5424, 5283, 5415].indexOf(campaign.campaignID) > -1 && destinationUrl === '#form' && (
                            <div className="absolute -right-2 bottom-24">
                                <Image
                                    src={`/assets/landings/rentinazo/icono150_black.png`}
                                    width={300 / 4}
                                    height={250 / 4}
                                    alt=""
                                />
                            </div>
                        )}
                         */}
                        {/*
                        {Comerciales2.indexOf(campaign.segment) > -1 && (
                            <div className="absolute -right-2 bottom-24">
                                <Image
                                    src={`/assets/landings/furgonetas/Icono_250.png`}
                                    width={300 / 4}
                                    height={300 / 4}
                                    alt=""
                                />
                            </div>
                        )}
                         */}

                        {/*
                        {campaign.stockRegistered > 0 &&
                            (campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote ||
                                campaign?.bajadaDePrecio) && (
                                <div className="absolute -right-2 bottom-24">
                                    <Image
                                        src={`/assets/landings/rentinazo/sello-rentinazo.png`}
                                        width={194 / 4}
                                        height={208 / 4}
                                        alt="Oferta Rentinazo."
                                    />
                                </div>
                            )}
                            */}
                    </div>
                    <div className="rounded-lg border border-gray-300 bg-white px-3 py-1 shadow-sm">
                        <div className="flex flex-row">
                            <div className="flex flex-shrink flex-col justify-center text-left">
                                {campaign?.priceFrom || campaign?.makeModel.indexOf('VIRTUAL') > -1 ? (
                                    <>
                                        <div
                                            className={`${getVariantStyles(
                                                campaign.displayVariant,
                                                3
                                            )} text-base font-semibold`}
                                        >
                                            Desde
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <Image
                                                src={
                                                    campaign.displayVariant === 'orange'
                                                        ? '/assets/icons/combustible-orange.svg'
                                                        : '/assets/icons/combustible.svg'
                                                }
                                                height={23}
                                                width={23}
                                                alt="Combustible"
                                                style={{
                                                    maxWidth: '100%',
                                                    height: 'auto',
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`${getVariantStyles(
                                                campaign.displayVariant,
                                                3
                                            )} whitespace-nowrap text-xs font-semibold`}
                                        >
                                            {fuel}
                                        </div>
                                        <div
                                            className={`${getVariantStyles(
                                                campaign.displayVariant,
                                                3
                                            )} text-xs font-semibold`}
                                        >
                                            {gear}
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="flex-grow text-right">
                                {tipologia === 'particulares' ? (
                                    <>
                                        {previousPrice ? (
                                            <div className="leading-nong text-sm text-lpsecondary line-through">
                                                {roundNumber(previousPrice * getVAT())} €
                                            </div>
                                        ) : (
                                            <div className="leadning-none text-sm text-lpsecondary">&nbsp;</div>
                                        )}
                                        <div className="text-3xl font-bold leading-none text-lpprimary">
                                            {roundNumber(quoteWithVAT)}€
                                        </div>
                                        <div className="text-xs text-lpprimary ">al mes IVA incluido</div>
                                        <div className="text-neutral20 text-[9px]">
                                            {roundNumber(quoteWithoutVAT)}€&nbsp;al&nbsp;mes&nbsp;+&nbsp;IVA
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {previousPrice ? (
                                            <div className="leading-nong text-sm text-lpsecondary line-through">
                                                {roundNumber(previousPrice)} €
                                            </div>
                                        ) : (
                                            <div className="leadning-none text-sm text-lpsecondary">&nbsp;</div>
                                        )}
                                        <div className="text-3xl font-bold leading-none text-lpprimary">
                                            {roundNumber(quoteWithoutVAT)}€
                                        </div>
                                        <div className="text-xs text-lpprimary ">al mes + IVA</div>
                                        <div className="text-neutral20 text-[9px]">
                                            {roundNumber(quoteWithVAT)}€&nbsp;al&nbsp;mes&nbsp;IVA&nbsp;incluido
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {campaign.outOfStock && (
                <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center">
                    <div className="inline-block rounded-full border border-lpprimary bg-white px-4 py-2 text-lpprimary">
                        AGOTADO
                    </div>
                </div>
            )}
        </div>
    )

    if (noLink) {
        return <>{Content}</>
    }
    return (
        <Link href={campaign.outOfStock ? '#' : destinationUrl} passHref onClick={handleClick}>
            {Content}
        </Link>
    )
}
